import { Container, Paper, Grid, Button, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
}));

export const HeaderPaper = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#004d40',
    color: '#fff',
}));

export const Logo = styled('img')(({ theme }) => ({
    width: '150px', // Adjust the width as needed
    marginBottom: theme.spacing(2),
}));

export const IconWrapper = styled('div')(({ theme }) => ({
    fontSize: 60,
    color: '#004d40',
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#004d40',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#00332e',
    },
}));

export const SectionGrid = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

export const FooterPaper = styled(Paper)(({ theme }) => ({
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#004d40',
    color: '#fff',
}));

export const FooterLink = styled(MuiLink)(({ theme }) => ({
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));
