// src/components/ProgressBar.jsx

import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

const steps = [
    'Who is Filing this Complaint?',
    'Victim Information',
    'Financial Transaction(s)',
    'Description of Incident',
    'Information About The Subject(s)',
    'Other Information',
    'Privacy & Signature',
];

function ProgressBar({ activeStep }) {
    return (
        <Stepper activeStep={activeStep} alternativeLabel sx={{ padding: '24px 0' }}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel 
                        sx={{
                            '& .MuiStepLabel-label': {
                                color: '#fff', // Set label color to white
                            },
                            '& .MuiStepLabel-label.Mui-active': {
                                color: '#fff', // Ensure active step label is white
                            },
                            '& .MuiStepLabel-label.Mui-completed': {
                                color: '#fff', // Ensure completed step label is white
                            },
                        }}
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

export default ProgressBar;
