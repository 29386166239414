// src/components/FormSection7.jsx

import React from 'react';
import { Typography, Button, Checkbox, FormControlLabel, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'left',
    color: '#fff',
    backgroundColor: '#2c2c2c',
    marginBottom: theme.spacing(4),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#00796b',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#004d40',
    },
}));

const BackButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#757575',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#616161',
    },
}));

function FormSection7({ privacyConsent, onPrivacyConsentChange, onSubmit, onBack }) {
    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>
                Privacy & Signature
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please review the information you have provided and confirm your agreement to the terms below.
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={privacyConsent}
                        onChange={(e) => onPrivacyConsentChange(e.target.checked)}
                        color="primary"
                    />
                }
                label="I confirm that the information provided is accurate to the best of my knowledge and agree to the terms."
            />
            <BackButton variant="contained" onClick={onBack}>
                Back (Step 6)
            </BackButton>
            <SubmitButton variant="contained" onClick={onSubmit}>
                Submit Complaint
            </SubmitButton>
        </StyledPaper>
    );
}

export default FormSection7;
