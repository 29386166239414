import { Container, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#1e1e1e',
    color: '#fff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
    },
}));

export const HeaderPaper = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#004d40',
    color: '#fff',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
    },
}));

export const ContentBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
    },
}));

export const FooterPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#004d40',
    color: '#fff',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
}));
