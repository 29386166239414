import { styled } from '@mui/material/styles';
import { Paper, Typography, Box, Pagination, Button } from '@mui/material';

export const RootContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#121212',
    minHeight: '100vh',
    minWidth: '100vw',
    color: '#e0e0e0',
    fontFamily: 'monospace',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
}));

export const HeaderPaper = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#1e1e1e',
    color: '#e0e0e0',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
    },
}));

export const MapSection = styled('div')(({ theme }) => ({
    height: '300px',
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    border: '1px solid #333',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
        height: '400px',
    },
}));

export const TerminalText = styled(Typography)(({ theme }) => ({
    color: '#e0e0e0',
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    backgroundColor: '#1e1e1e',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
    '&:before': {
        content: '"$ "',
        marginRight: theme.spacing(1),
        color: '#4caf50',
    },
}));

export const TopCitiesSection = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: '#1e1e1e',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
    },
}));

export const StyledPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPaginationItem-root': {
        color: '#e0e0e0',
        '&.Mui-selected': {
            backgroundColor: '#4caf50',
            color: '#121212',
        },
        '&:hover': {
            backgroundColor: '#4caf50',
            color: '#121212',
        },
    },
    
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#1e1e1e',
    color: '#4caf50',
    textTransform: 'none',
    fontFamily: 'monospace',
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    '&:hover': {
        backgroundColor: '#4caf50',
        color: '#121212',
    },
}));
