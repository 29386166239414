// src/components/FormSection1.jsx

import React from 'react';
import { Typography, TextField, MenuItem, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'left',
    color: '#fff',
    backgroundColor: '#2c2c2c',
    marginBottom: theme.spacing(4),
}));

const NextButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#00796b',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#004d40',
    },
}));

function FormSection1({ category, fraudType, incidentLocation, onCategoryChange, onFraudTypeChange, onIncidentLocationChange, onNext }) {
    const fraudTypes = [
        'Online Scams',
        'Credit Card Fraud',
        'Identity Theft',
        'Email Phishing',
        'Ransomware',
        'Investment Fraud',
        'Lottery Scams',
        'Romance Scams',
        'Business Email Compromise',
        'Social Media Fraud',
        'Darknet Vendor Fraud',
        'Darknet Market Fraud',
        'Darknet Identity Theft',
        'Darknet Ransomware',
        'Darknet Phishing',
        'Darknet Money Laundering',
        'Darknet Drug Trafficking',
        'Darknet Weapons Trafficking',
        'Darknet Exploit Sale',
        'Darknet Human Trafficking',
        'Cryptocurrency Investment Fraud', // Fraudulent schemes involving cryptocurrency investments
        'Initial Coin Offering (ICO) Scams', // Fraudulent ICOs that promise high returns
        'Crypto Ponzi Schemes', // Investment scams that promise high returns with little risk
        'Crypto Mining Scams', // Fraudulent schemes related to cryptocurrency mining investments
        'Rug Pulls', // Fraudulent schemes where developers abandon a project after investors have invested
        'Crypto Phishing', // Phishing attacks targeting cryptocurrency holders or exchanges
        'Fake Cryptocurrency Exchanges', // Scams involving fake exchanges that steal user funds
        'Crypto Wallet Theft', // Theft of funds from cryptocurrency wallets
        'Cryptojacking', // Unauthorized use of a person's computer to mine cryptocurrency
        'Pump and Dump Schemes' // Market manipulation schemes where the price of a cryptocurrency is artificially inflated
    ];
    
    

    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>
                Who is Filing this Complaint?
            </Typography>
            <Typography variant="body1" gutterBottom>
                If you were the victim of this incident, please indicate so below. In the event that you are completing this form on behalf of another individual or business, please select NO and provide your contact information.
            </Typography>
            <TextField
                select
                label="Were you the victim in this incident?"
                value={category}
                onChange={(e) => onCategoryChange(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            style: {
                                backgroundColor: '#2c2c2c',
                                color: '#fff',
                            },
                        },
                    },
                    sx: {
                        backgroundColor: '#2c2c2c',
                        color: '#fff',
                        '.MuiSelect-select': {
                            color: '#fff',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff',
                        },
                    },
                }}
                InputProps={{
                    style: { color: '#fff', backgroundColor: '#2c2c2c' },
                }}
            >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
            </TextField>
            <TextField
                select
                label="Type of Fraud"
                value={fraudType}
                onChange={(e) => onFraudTypeChange(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            style: {
                                backgroundColor: '#2c2c2c',
                                color: '#fff',
                            },
                        },
                    },
                    sx: {
                        backgroundColor: '#2c2c2c',
                        color: '#fff',
                        '.MuiSelect-select': {
                            color: '#fff',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff',
                        },
                    },
                }}
                InputProps={{
                    style: { color: '#fff', backgroundColor: '#2c2c2c' },
                }}
            >
                {fraudTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Where did the incident take place? (e.g., website, domain, marketplace, chatroom)"
                value={incidentLocation}
                onChange={(e) => onIncidentLocationChange(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                InputProps={{
                    style: { color: '#fff', backgroundColor: '#2c2c2c' },
                }}
            />
            <NextButton variant="contained" onClick={onNext}>
                Next (Step 2)
            </NextButton>
        </StyledPaper>
    );
}

export default FormSection1;
