// src/components/FormSection5.jsx

import React from 'react';
import { Typography, TextField, Button, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'left',
    color: '#fff',
    backgroundColor: '#2c2c2c',
    marginBottom: theme.spacing(4),
}));

const NextButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#00796b',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#004d40',
    },
}));

const BackButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#757575',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#616161',
    },
}));

function FormSection5({ subjectInfoList, onSubjectInfoChange, onAddSubject, onRemoveSubject, onNext, onBack }) {
    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>
                Information About The Subject(s)
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please provide any information you have about the suspect(s) involved in this incident. This information is not required, but any details you can provide will help in identifying the cybercriminal(s).
            </Typography>
            {subjectInfoList.map((subjectInfo, index) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                    <TextField
                        label={`Subject Name ${index + 1}`}
                        value={subjectInfo.name}
                        onChange={(e) => onSubjectInfoChange(index, 'name', e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label={`Subject Email ${index + 1}`}
                        value={subjectInfo.email}
                        onChange={(e) => onSubjectInfoChange(index, 'email', e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label={`Subject Address ${index + 1}`}
                        value={subjectInfo.address}
                        onChange={(e) => onSubjectInfoChange(index, 'address', e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label={`Username or Vendor Name ${index + 1}`}
                        value={subjectInfo.username}
                        onChange={(e) => onSubjectInfoChange(index, 'username', e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label={`Additional Identifying Information ${index + 1}`}
                        value={subjectInfo.additionalInfo}
                        onChange={(e) => onSubjectInfoChange(index, 'additionalInfo', e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    {index > 0 && (
                        <IconButton onClick={() => onRemoveSubject(index)} aria-label="remove subject" color="secondary">
                            <RemoveIcon />
                        </IconButton>
                    )}
                </div>
            ))}
            <Button
                onClick={onAddSubject}
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                style={{ marginTop: '20px' }}
            >
                Add Another Subject
            </Button>
            <div style={{ marginTop: '20px' }}>
                <BackButton variant="contained" onClick={onBack}>
                    Back (Step 4)
                </BackButton>
                <NextButton variant="contained" onClick={onNext}>
                    Next (Step 6)
                </NextButton>
            </div>
        </StyledPaper>
    );
}

export default FormSection5;

