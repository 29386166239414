import React from 'react';
import { Container, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RootContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
    color: '#333',
}));

const FAQPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#ffffff',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
}));

const FAQSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

function FAQs() {
    return (
        <RootContainer maxWidth="md">
            <FAQPaper>
                <Typography variant="h4" component="h1" gutterBottom>
                    Frequently Asked Questions (FAQs)
                </Typography>

                {/* Existing FAQ Sections */}
                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">What is CCRNet?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                CCRNet (Cyber Crime Reporting Network) is a private organization dedicated to receiving, processing, and sharing cybercrime complaints with law enforcement agencies, private investigators, and research institutions. Our goal is to help identify and investigate criminal activities in the digital space.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>

                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How do I file a complaint?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To file a complaint, visit our <Link href="/complaint">File a Complaint</Link> page, where you can fill out the necessary information regarding the incident. Please ensure that all details provided are accurate to the best of your knowledge.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>

                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">What information will I need to provide?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                When filing a complaint, you will need to provide personal identification information (such as your name and email address), details of the incident (e.g., dates, descriptions), and any relevant documentation or evidence that supports your claim.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>

                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How is my information used?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The information you provide is used to investigate and identify potential criminal activities. CCRNet may share your information with law enforcement agencies, private investigators, and research institutions for the purpose of addressing and preventing cybercrime. Your data is handled with the utmost care and is protected by encryption.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>

                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Is my information secure?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, all information submitted to CCRNet is encrypted via Secure Socket Layer (SSL) technology. We are committed to maintaining the confidentiality and security of your personal data. Please refer to our <Link href="/privacy-policy">Privacy Policy</Link> for more details on how we protect your information.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>

                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Will I receive updates about my complaint?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                After submitting a complaint, you may receive updates or requests for additional information as necessary. However, the level of communication may vary depending on the nature of the investigation and the involved entities.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>

                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Who can I contact for further assistance?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                If you have any additional questions or require further assistance, please contact us at <Link href="mailto:support@ccrnet.org">support@ccrnet.org</Link>. We are here to help you navigate the complaint process and provide support as needed.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>

                {/* New FAQ Section */}
                <FAQSection>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Can I file a complaint anonymously?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, you can file a complaint anonymously. If you prefer not to provide your name or email address, simply leave those fields blank when filling out the complaint form. However, please note that this may limit our ability to follow up with you for additional information or provide updates on the status of your complaint.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </FAQSection>
            </FAQPaper>
        </RootContainer>
    );
}

export default FAQs;

