import React from 'react';
import { Grid, Typography, Card, CardContent, Box, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import LockIcon from '@mui/icons-material/Lock';
import AlertIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ShieldIcon from '@mui/icons-material/Security';
import FAQIcon from '@mui/icons-material/HelpOutline';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import XIcon from '@mui/icons-material/X';
import {
    RootContainer,
    HeaderPaper,
    Logo,
    IconWrapper,
    StyledPaper,
    StyledButton,
    SectionGrid,
    FooterPaper,
    FooterLink,
} from './HomeStyles'; // Import the styles


function Home() {
  return (
    <RootContainer maxWidth="lg">
      {/* Header */}
      <HeaderPaper>
        <Logo src="/logo.png" alt="Organization Logo" />
        <Typography variant="h3" component="h1" gutterBottom>
          Cyber Crime Reporting Network (CCRNet)
        </Typography>
        <Typography variant="h6" component="p">
          Protecting Our Digital World Together
        </Typography>
      </HeaderPaper>

      {/* Main Content */}
      <Grid container spacing={4}>
        {/* Protect One Another Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <IconWrapper as={LockIcon} />
            <Typography variant="h5" component="h2">
              Protect One Another
            </Typography>
            <Typography variant="body1" component="p">
              CCRNet is the nation's central hub for reporting cyber crimes.
              Your reports help safeguard our communities by enabling quick
              responses to threats.
            </Typography>
            <StyledButton variant="contained" component={Link} to="/complaint">
              File a Complaint
            </StyledButton>
          </StyledPaper>
        </Grid>

        {/* Reporting Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <IconWrapper as={ReportProblemIcon} />
            <Typography variant="h5" component="h2">
              Reporting Cyber Crimes
            </Typography>
            <Typography variant="body1" component="p">
              Timely reporting of cyber incidents allows authorities to respond
              more effectively, protecting you and others from harm.
            </Typography>
            <StyledButton
              variant="contained"
              component={Link}
              to="/other-crimes"
            >
              Other Types of Crimes
            </StyledButton>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Announcements and Alerts Section */}
      <SectionGrid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <IconWrapper as={AnnouncementIcon} />
              <Typography variant="h6" component="h3">
                Latest Announcements
              </Typography>
              <Typography variant="body2" component="p">
                Stay informed with the latest news and updates about cyber
                threats and online safety measures.
              </Typography>
              <StyledButton variant="contained">
                View Announcements
              </StyledButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <IconWrapper as={AlertIcon} />
              <Typography variant="h6" component="h3">
                Consumer Alerts
              </Typography>
              <Typography variant="body2" component="p">
                Learn about the latest cyber scams and how to protect yourself
                and your loved ones.
              </Typography>
              <StyledButton variant="contained" component={Link} to="/alerts">
                View Alerts
              </StyledButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <IconWrapper as={ShieldIcon} />
              <Typography variant="h6" component="h3">
                Industry Alerts
              </Typography>
              <Typography variant="body2" component="p">
                Understand the evolving cyber threats targeting businesses and
                how to mitigate risks.
              </Typography>
              <StyledButton variant="contained">View Alerts</StyledButton>
            </CardContent>
          </Card>
        </Grid>
      </SectionGrid>

      {/* FAQs Section */}
      <SectionGrid container spacing={4}>
        <Grid item xs={12}>
          <StyledPaper>
            <IconWrapper as={FAQIcon} />
            <Typography variant="h5" component="h2">
              Frequently Asked Questions
            </Typography>
            <Typography variant="body1" component="p">
              Get answers to common questions about reporting cyber crimes,
              staying safe online, and how CCRNet works.
            </Typography>
            <StyledButton variant="contained" component={Link} to="/faqs">
              Browse FAQs
            </StyledButton>
          </StyledPaper>
        </Grid>
      </SectionGrid>
      {/* Footer */}
      <FooterPaper>
        <Typography variant="body2" component="p">
          &copy; 2024 CCRNet.org - Powered by Hive Forensics AI |{" "}
          <FooterLink href="https://www.hiveforensics.com">
            Visit Hive Forensics AI
          </FooterLink>
        </Typography>
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" component="span">
            Follow us on
          </Typography>
          <FooterLink
            href="https://twitter.com/CCRNetHQ"
            target="_blank"
            sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}
          >
            <XIcon sx={{ marginRight: "4px", fontSize: "20px" }} />
            <Typography variant="body2" component="span">
              @CCRNetHQ
            </Typography>
          </FooterLink>
        </Box>
      </FooterPaper>
    </RootContainer>
  );
}
export default Home;
