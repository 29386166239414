// src/components/FormSection6.jsx

import React from 'react';
import { Typography, TextField, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'left',
    color: '#fff',
    backgroundColor: '#2c2c2c',
    marginBottom: theme.spacing(4),
}));

const NextButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#00796b',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#004d40',
    },
}));

const BackButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#757575',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#616161',
    },
}));

function FormSection6({ additionalInfo, onAdditionalInfoChange, onNext, onBack }) {
    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>
                Other Information
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please provide any additional information that may be relevant to this incident.
            </Typography>
            <TextField
                label="Additional Information"
                value={additionalInfo}
                onChange={(e) => onAdditionalInfoChange(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={6}
                InputLabelProps={{
                    style: { color: '#fff' }, // Label text color
                }}
                InputProps={{
                    style: { color: '#fff' }, // Input text color
                }}
            />
            <BackButton variant="contained" onClick={onBack}>
                Back (Step 5)
            </BackButton>
            <NextButton variant="contained" onClick={onNext}>
                Next (Step 7)
            </NextButton>
        </StyledPaper>
    );
}

export default FormSection6;
