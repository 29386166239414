import React from 'react';
import { Container, Paper, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const RootContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
}));

const HeaderPaper = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#004d40',
    color: '#fff',
}));

const SectionPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#004d40',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#00332e',
    },
}));

const FooterPaper = styled(Paper)(({ theme }) => ({
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#004d40',
    color: '#fff',
}));

const FooterLink = styled('a')(({ theme }) => ({
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

function OtherCrimes() {
    return (
        <RootContainer maxWidth="lg">
            {/* Header */}
            <HeaderPaper>
                <Typography variant="h3" component="h1" gutterBottom>
                    Understanding Cyber Crimes
                </Typography>
                <Typography variant="h6" component="p">
                    How We Combat the Ever-Evolving Threat Landscape
                </Typography>
            </HeaderPaper>

            {/* Cybercrime Overview */}
            <SectionPaper>
                <Typography variant="h5" gutterBottom>
                    What is Cybercrime?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Cybercrime refers to criminal activities carried out using computers, the internet, and other digital technologies. These crimes can range from data breaches and identity theft to more severe forms such as ransomware attacks and financial fraud.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    As a leading cyber forensics and AI company, we at CCRNet specialize in identifying, investigating, and mitigating these cyber threats. Our team is equipped with cutting-edge technology to track down perpetrators and secure digital assets.
                </Typography>
            </SectionPaper>

            {/* Types of Cybercrime */}
            <SectionPaper>
                <Typography variant="h5" gutterBottom>
                    Types of Cybercrime
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Here are some common types of cybercrimes that individuals and organizations may face:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1"><strong>Identity Theft:</strong> Stealing personal information to commit fraud.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Ransomware:</strong> Malicious software that encrypts data and demands ransom for its release.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Email Phishing:</strong> Deceptive emails that trick users into revealing sensitive information.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Credit Card Fraud:</strong> Unauthorized use of credit card information for purchases.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Online Scams:</strong> Fraudulent schemes carried out over the internet.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Social Engineering:</strong> Manipulating individuals to disclose confidential information.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Business Email Compromise (BEC):</strong> Targeting businesses to steal funds or sensitive information.</Typography>
                    </li>
                </ul>
            </SectionPaper>

            {/* Our Approach */}
            <SectionPaper>
                <Typography variant="h5" gutterBottom>
                    Our Approach to Combatting Cybercrime
                </Typography>
                <Typography variant="body1" gutterBottom>
                    At CCRNet, we employ a multi-layered approach to combat cybercrime:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1"><strong>Advanced AI Technology:</strong> Utilizing artificial intelligence to detect and prevent cyber threats in real time.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Cyber Forensics:</strong> Investigating cybercrimes and tracing the source of attacks.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Incident Response:</strong> Providing immediate assistance to contain and mitigate cyber incidents.</Typography>
                    </li>
                    <li>
                        <Typography variant="body1"><strong>Education and Awareness:</strong> Training organizations and individuals to recognize and avoid cyber threats.</Typography>
                    </li>
                </ul>
                <StyledButton variant="contained" component={Link} to="/complaint">
                    Report a Cybercrime
                </StyledButton>
            </SectionPaper>

            {/* Footer */}
            <FooterPaper>
                <Typography variant="body2" component="p">
                    &copy; 2024 CCRNet.org - Powered by Hive Forensics AI |{' '}
                    <FooterLink href="https://www.hiveforensics.com">
                        Visit Hive Forensics AI
                    </FooterLink>
                </Typography>
            </FooterPaper>
        </RootContainer>
    );
}

export default OtherCrimes;
