import { useState } from 'react';
import { ref, push, set } from 'firebase/database';
import database from '../firebaseConfig';  // Adjust the path as necessary

const useComplaintForm = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const submitComplaint = async (formData) => {
        setLoading(true);
        setError(null);

        try {
            // Get user's location if required (this is optional based on your needs)
            const location = await new Promise((resolve, reject) => {
                if (!navigator.geolocation) {
                    return reject(new Error('Geolocation is not supported by your browser.'));
                }

                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                    },
                    (err) => reject(err),
                    { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
                );
            });

            const complaintRef = push(ref(database, 'complaints'));

            // Formatting the data for saving
            const formattedData = {
                category: formData.category,
                fraudType: formData.fraudType,  // Include the fraud type
                incidentLocation: formData.incidentLocation,  // Include the incident location
                victim: {
                    name: formData.victimInfo.name,
                    email: formData.victimInfo.email,
                },
                transaction: {
                    amount: formData.transactionInfo.amount,
                    date: formData.transactionInfo.date,
                    type: formData.transactionInfo.type,  // Include the transaction type
                },
                incidentDescription: formData.incidentDescription,
                subjects: formData.subjectInfoList.map(subject => ({
                    name: subject.name,
                    email: subject.email,
                    address: subject.address,
                    username: subject.username,
                    additionalInfo: subject.additionalInfo,
                })), // Map through the subjectInfoList and save each subject
                additionalInfo: formData.additionalInfo,
                privacyConsent: formData.privacyConsent,
                timestamp: new Date().toISOString(),
                location,  // Save the geolocation data
            };

            // Saving the complaint to the database
            await set(complaintRef, formattedData);

            setLoading(false);
            return { success: true };
        } catch (err) {
            setLoading(false);
            setError(err.message);
            return { success: false, error: err.message };
        }
    };

    return { submitComplaint, loading, error };
};

export default useComplaintForm;

