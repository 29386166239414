import React, { useState } from 'react';
import { Typography,  Dialog, Box, Snackbar, Alert} from '@mui/material';
import ProgressBar from '../components/ProgressBar';
import FormSection1 from '../components/FormSection1';
import FormSection2 from '../components/FormSection2';
import FormSection3 from '../components/FormSection3';
import FormSection4 from '../components/FormSection4';
import FormSection5 from '../components/FormSection5';
import FormSection6 from '../components/FormSection6';
import FormSection7 from '../components/FormSection7';
import Disclaimer from '../components/Disclaimer';
import useComplaintForm from '../hooks/useComplaintForm';
import { useNavigate } from 'react-router-dom';
import {
    RootContainer,
    HeaderPaper,
    ContentBox,
    FooterPaper,
} from './ComplaintStyles';


function Complaint() {
    const [activeStep, setActiveStep] = useState(0);
    const [disclaimerOpen, setDisclaimerOpen] = useState(true);
    const { submitComplaint, loading, error } = useComplaintForm();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success')
    const navigate = useNavigate(); // Hook for navigation

    const [formData, setFormData] = useState({
        category: '',
        fraudType: '',
        incidentLocation: '',
        victimInfo: { name: '', email: '' },
        transactionInfo: { amount: '', date: '', type: '' },
        incidentDescription: '',
        subjectInfoList: [
            {
                name: '',
                email: '',
                address: '',
                username: '',
                additionalInfo: '',
            },
        ],
        additionalInfo: '',
        privacyConsent: false,
    });

    const handleNext = () => setActiveStep((prev) => prev + 1);
    const handleBack = () => setActiveStep((prev) => prev - 1);
    const handleDisclaimerAccept = () => setDisclaimerOpen(false);

    const handleChange = (section, field, value) => {
        setFormData((prev) => ({
            ...prev,
            [section]: { ...prev[section], [field]: value },
        }));
    };

    const handleSimpleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleSubjectInfoChange = (index, field, value) => {
        const newSubjectInfoList = [...formData.subjectInfoList];
        newSubjectInfoList[index][field] = value;
        setFormData((prev) => ({ ...prev, subjectInfoList: newSubjectInfoList }));
    };

    const handleAddSubject = () => {
        setFormData((prev) => ({
            ...prev,
            subjectInfoList: [
                ...prev.subjectInfoList,
                { name: '', email: '', address: '', username: '', additionalInfo: '' },
            ],
        }));
    };

    const handleRemoveSubject = (index) => {
        const newSubjectInfoList = formData.subjectInfoList.filter((_, i) => i !== index);
        setFormData((prev) => ({ ...prev, subjectInfoList: newSubjectInfoList }));
    };

    const handleSubmit = async () => {
        const result = await submitComplaint(formData);

        if (result.success) {
            setSnackbarMessage('Your complaint has been submitted successfully. We will review the information provided and reach out if further details are needed.');
            setSnackbarSeverity('success');

            // Wait 5 seconds and then navigate to the home page
            setTimeout(() => {
                navigate('/');
            }, 5000);
        } else {
            setSnackbarMessage('There was an error submitting your complaint. Please try again or contact support if the problem persists.');
            setSnackbarSeverity('error');
        }

        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const renderFormSection = () => {
        const {
            category, fraudType, incidentLocation, victimInfo, transactionInfo, incidentDescription, subjectInfoList, additionalInfo, privacyConsent,
        } = formData;

        switch (activeStep) {
            case 0:
                return (
                    <FormSection1
                        category={category}
                        fraudType={fraudType}
                        incidentLocation={incidentLocation}
                        onCategoryChange={(value) => handleSimpleChange('category', value)}
                        onFraudTypeChange={(value) => handleSimpleChange('fraudType', value)}
                        onIncidentLocationChange={(value) => handleSimpleChange('incidentLocation', value)}
                        onNext={handleNext}
                    />
                );
            case 1:
                return (
                    <FormSection2
                        victimInfo={victimInfo}
                        onVictimInfoChange={(field, value) => handleChange('victimInfo', field, value)}
                        onNext={handleNext}
                        onBack={handleBack}
                    />
                );
            case 2:
                return (
                    <FormSection3
                        transactionInfo={transactionInfo}
                        onTransactionInfoChange={(field, value) => handleChange('transactionInfo', field, value)}
                        onNext={handleNext}
                        onBack={handleBack}
                    />
                );
            case 3:
                return (
                    <FormSection4
                        incidentDescription={incidentDescription}
                        onIncidentDescriptionChange={(value) => handleSimpleChange('incidentDescription', value)}
                        onNext={handleNext}
                        onBack={handleBack}
                    />
                );
            case 4:
                return (
                    <FormSection5
                        subjectInfoList={subjectInfoList}  // Pass the list of subjects
                        onSubjectInfoChange={handleSubjectInfoChange}  // Pass the handler for changing subject info
                        onAddSubject={handleAddSubject}  // Pass the handler for adding a new subject
                        onRemoveSubject={handleRemoveSubject}  // Pass the handler for removing a subject
                        onNext={handleNext}
                        onBack={handleBack}
                    />
                );
            case 5:
                return (
                    <FormSection6
                        additionalInfo={additionalInfo}
                        onAdditionalInfoChange={(value) => handleSimpleChange('additionalInfo', value)}
                        onNext={handleNext}
                        onBack={handleBack}
                    />
                );
            case 6:
                return (
                    <FormSection7
                        privacyConsent={privacyConsent}
                        onPrivacyConsentChange={(value) => handleSimpleChange('privacyConsent', value)}
                        onSubmit={handleSubmit}
                        onBack={handleBack}
                    />
                );
            default:
                return <Typography variant="h6">All steps completed</Typography>;
        }
    };

    return (
      <RootContainer maxWidth="lg">
        {/* Disclaimer Dialog */}
        <Dialog
          open={disclaimerOpen}
          onClose={handleDisclaimerAccept}
          fullWidth
          maxWidth="xs" // Adjusted to "xs" for better handling on small screens
          PaperProps={{
            style: {
              padding: "16px",
              borderRadius: "8px", // Optional: to match styling
            },
          }}
        >
          <Disclaimer onAccept={handleDisclaimerAccept} />
        </Dialog>

        {/* Header */}
        <HeaderPaper>
          <Typography variant="h4" component="h1" gutterBottom>
            File a Cybercrime Complaint
          </Typography>
        </HeaderPaper>

        {/* Progress Bar */}
        <ContentBox>
          <Box mb={4}>
            <ProgressBar activeStep={activeStep} />
          </Box>

          {/* Form Section */}
          {renderFormSection()}
        </ContentBox>

        {/* Footer */}
        <FooterPaper>
          <Typography variant="body2">
            &copy; 2024 CCRNet.org - Powered by Hive Forensics AI |{" "}
            <a
              href="https://www.hiveforensics.com"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Visit Hive Forensics AI
            </a>
          </Typography>
        </FooterPaper>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </RootContainer>
    );
}

export default Complaint;
