import React from 'react';
import { Typography, TextField, MenuItem, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'left',
    color: '#fff',
    backgroundColor: '#2c2c2c',
    marginBottom: theme.spacing(4),
}));

const NextButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#00796b',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#004d40',
    },
}));

const BackButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#757575',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#616161',
    },
}));

function FormSection3({ transactionInfo, onTransactionInfoChange, onNext, onBack }) {
    const transactionTypes = [
        'Western Union',
        'MoneyGram',
        'Bank Wire',
        'Credit Card',
        'Crypto',
        'PayPal',
        'Gift Cards',
        'Cash',
        'Other',
        'N/A',
        'None',
    ];

    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>
                Financial Transaction(s)
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please provide details about any financial transactions related to this incident. If no transaction was involved, enter "N/A".
            </Typography>
            <TextField
                label="Transaction Amount"
                value={transactionInfo.amount}
                onChange={(e) => onTransactionInfoChange('amount', e.target.value)}  // Use e.target.value
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' }, // Label text color
                }}
                InputProps={{
                    style: { color: '#fff' }, // Input text color
                }}
            />
            <TextField
                label="Transaction Date"
                type="date"
                value={transactionInfo.date}
                onChange={(e) => onTransactionInfoChange('date', e.target.value)}  // Use e.target.value
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                    style: { color: '#fff' }, // Label text color
                }}
                InputProps={{
                    style: { color: '#fff' }, // Input text color
                }}
                required
            />
            <TextField
                select
                label="Transaction Type"
                value={transactionInfo.type}
                onChange={(e) => onTransactionInfoChange('type', e.target.value)}  // Use e.target.value
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' }, // Label text color
                }}
                InputProps={{
                    style: { color: '#fff' }, // Input text color
                }}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            style: {
                                backgroundColor: '#2c2c2c',
                                color: '#fff',
                            },
                        },
                    },
                    sx: {
                        color: '#fff', // Ensure the selected text is white
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#fff', // Ensure the outline is white
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#fff', // Ensure the outline remains white when focused
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff', // Ensure the dropdown arrow is white
                        },
                    },
                }}
            >
                {transactionTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </TextField>
            <BackButton variant="contained" onClick={onBack}>
                Back (Step 2)
            </BackButton>
            <NextButton variant="contained" onClick={onNext}>
                Next (Step 4)
            </NextButton>
        </StyledPaper>
    );
}

export default FormSection3;

