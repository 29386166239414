const coordinateToCountry = {
    '40.7128,-74.0060': 'United States', // New York
    '51.5074,-0.1278': 'United Kingdom', // London
    '48.8566,2.3522': 'France', // Paris
    '35.6895,139.6917': 'Japan', // Tokyo
    '52.5200,13.4050': 'Germany', // Berlin
    '41.9028,12.4964': 'Italy', // Rome
    '55.7558,37.6173': 'Russia', // Moscow
    '39.9042,116.4074': 'China', // Beijing
    '28.6139,77.2090': 'India', // New Delhi
    '1.3521,103.8198': 'Singapore', // Singapore
    '33.8688,151.2093': 'Australia', // Sydney
    '59.3293,18.0686': 'Sweden', // Stockholm
    '60.1695,24.9354': 'Finland', // Helsinki
    '40.4168,-3.7038': 'Spain', // Madrid
    '48.2082,16.3738': 'Austria', // Vienna
    '55.6761,12.5683': 'Denmark', // Copenhagen
    '25.276987,55.296249': 'United Arab Emirates', // Dubai
    '30.0444,31.2357': 'Egypt', // Cairo
    '32.0853,34.7818': 'Israel', // Tel Aviv
    '6.5244,3.3792': 'Nigeria', // Lagos
    '43.6532,-79.3832': 'Canada', // Toronto
    '19.4326,-99.1332': 'Mexico', // Mexico City
    '14.5995,120.9842': 'Philippines', // Manila
    '3.1390,101.6869': 'Malaysia', // Kuala Lumpur
    '37.5665,126.9780': 'South Korea', // Seoul
    '33.6844,73.0479': 'Pakistan' // Islamabad
};

export default coordinateToCountry;
