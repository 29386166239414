import React from 'react';
import { Container, Paper, Typography, Link } from '@mui/material'; // Add Link to the import statement
import { styled } from '@mui/material/styles';

const RootContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
    color: '#333',
}));

const PolicyPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#ffffff',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
}));

function PrivacyPolicy() {
    return (
        <RootContainer maxWidth="md">
            <PolicyPaper>
                <Typography variant="h4" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Welcome to CCRNet (Cyber Crime Reporting Network). We respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    We may collect personal information that you voluntarily provide to us when you use our services. This includes, but is not limited to:
                </Typography>
                <ul>
                    <li><Typography variant="body1">Personal Identification Information (e.g., name, email address, phone number)</Typography></li>
                    <li><Typography variant="body1">Details of the incident or complaint you report</Typography></li>
                    <li><Typography variant="body1">Technical data (e.g., IP address, browser type, operating system)</Typography></li>
                </ul>

                <Typography variant="h5" component="h2" gutterBottom>
                    How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    The information we collect is used to:
                </Typography>
                <ul>
                    <li><Typography variant="body1">Process and investigate the complaints you submit</Typography></li>
                    <li><Typography variant="body1">Share with law enforcement agencies, private investigators, and research institutions for the purpose of identifying and investigating criminal activities</Typography></li>
                    <li><Typography variant="body1">Improve our services and website performance</Typography></li>
                    <li><Typography variant="body1">Communicate with you regarding your complaint or other inquiries</Typography></li>
                </ul>

                <Typography variant="h5" component="h2" gutterBottom>
                    Information Sharing and Disclosure
                </Typography>
                <Typography variant="body1" paragraph>
                    We may share your information with third parties under the following circumstances:
                </Typography>
                <ul>
                    <li><Typography variant="body1">With law enforcement, government agencies, or authorized third parties, as required by law or necessary to protect our services</Typography></li>
                    <li><Typography variant="body1">With trusted service providers who assist us in operating our website and conducting our business</Typography></li>
                    <li><Typography variant="body1">In connection with a merger, sale, or other transfer of assets</Typography></li>
                </ul>

                <Typography variant="h5" component="h2" gutterBottom>
                    Data Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We implement a variety of security measures to maintain the safety of your personal information. All information you provide is encrypted via Secure Socket Layer (SSL) technology.
                </Typography>
                <Typography variant="body1" paragraph>
                    Despite these measures, please be aware that no method of transmission over the internet or method of electronic storage is completely secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Your Rights and Choices
                </Typography>
                <Typography variant="body1" paragraph>
                    You have the right to access, correct, or delete the personal information we hold about you. You may also object to our processing of your information or request that we restrict its processing. To exercise these rights, please contact us using the information provided below.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and we will indicate the date of the latest revision at the top of the policy.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                </Typography>
                <Typography variant="body1" paragraph>
                    CCRNet (Cyber Crime Reporting Network)<br />
                    Email: <Link href="mailto:support@ccrnet.org">support@ccrnet.org</Link><br />
                    Address: [Your Business Address Here]
                </Typography>
            </PolicyPaper>
        </RootContainer>
    );
}

export default PrivacyPolicy;
