import { useState, useEffect } from 'react';

// Expanded data set for alerts
const initialAlerts = [
    {
        id: 1,
        title: 'Phishing Attack Warning',
        date: 'August 15, 2024',
        description: 'Be aware of recent phishing attacks targeting our community. Ensure you verify the sender before clicking on any links.',
        position: [40.7128, -74.0060], // New York
    },
    {
        id: 2,
        title: 'Ransomware Alert',
        date: 'July 30, 2024',
        description: 'A new strain of ransomware is circulating. Back up your data regularly and avoid downloading suspicious attachments.',
        position: [34.0522, -118.2437], // Los Angeles
    },
    {
        id: 3,
        title: 'Social Media Fraud',
        date: 'July 20, 2024',
        description: 'Scammers are using fake profiles on social media to trick users into revealing personal information. Stay vigilant.',
        position: [51.5074, -0.1278], // London
    },
    {
        id: 4,
        title: 'Fake Job Offer Scams',
        date: 'June 10, 2024',
        description: 'Beware of fake job offers that require upfront payments. Legitimate employers do not ask for money during the hiring process.',
        position: [48.8566, 2.3522], // Paris
    },
    {
        id: 5,
        title: 'Online Shopping Fraud',
        date: 'May 25, 2024',
        description: 'Fraudulent websites are mimicking legitimate online stores. Double-check URLs and avoid deals that seem too good to be true.',
        position: [35.6895, 139.6917], // Tokyo
    },
    {
        id: 6,
        title: 'Cryptocurrency Investment Scams',
        date: 'April 14, 2024',
        description: 'Scammers are promising high returns on cryptocurrency investments. Always research and verify before investing your money.',
        position: [37.7749, -122.4194], // San Francisco
    },
    {
        id: 7,
        title: 'Business Email Compromise',
        date: 'March 23, 2024',
        description: 'Cybercriminals are targeting businesses with fraudulent email requests. Verify all email requests for payment or sensitive information.',
        position: [55.7558, 37.6173], // Moscow
    },
    {
        id: 8,
        title: 'IoT Device Vulnerability',
        date: 'February 17, 2024',
        description: 'A new vulnerability in IoT devices has been discovered. Ensure your devices are updated with the latest firmware.',
        position: [19.0760, 72.8777], // Mumbai
    },
    {
        id: 9,
        title: 'Malware Targeting Healthcare Sector',
        date: 'January 29, 2024',
        description: 'Healthcare institutions are being targeted by a new strain of malware. Ensure all systems are backed up and protected.',
        position: [41.9028, 12.4964], // Rome
    },
    {
        id: 10,
        title: 'Zero-Day Exploit Detected',
        date: 'December 5, 2023',
        description: 'A zero-day exploit has been detected in widely used software. Apply the latest security patches immediately.',
        position: [52.5200, 13.4050], // Berlin
    },
    {
        id: 11,
        title: 'Data Breach in Financial Sector',
        date: 'November 20, 2023',
        description: 'A major data breach has affected several financial institutions. Monitor your accounts for suspicious activity.',
        position: [40.4168, -3.7038], // Madrid
    },
    {
        id: 12,
        title: 'Social Engineering Scams',
        date: 'October 15, 2023',
        description: 'Cybercriminals are using social engineering techniques to trick individuals into giving away personal information. Stay alert.',
        position: [39.9042, 116.4074], // Beijing
    },
    {
        id: 13,
        title: 'DNS Hijacking Incident',
        date: 'September 30, 2023',
        description: 'A DNS hijacking incident has redirected several websites to malicious domains. Verify the URLs before entering personal information.',
        position: [33.8688, 151.2093], // Sydney
    },
    {
        id: 14,
        title: 'Rogue Wi-Fi Networks Detected',
        date: 'August 25, 2023',
        description: 'Rogue Wi-Fi networks have been detected in several public spaces. Avoid connecting to unsecured or unfamiliar networks.',
        position: [1.3521, 103.8198], // Singapore
    },
    {
        id: 15,
        title: 'Cryptomining Malware Alert',
        date: 'July 18, 2023',
        description: 'A new cryptomining malware is spreading through compromised websites. Ensure your anti-virus software is up to date.',
        position: [55.8642, -4.2518], // Glasgow
    },
    {
        id: 16,
        title: 'Supply Chain Attack Warning',
        date: 'June 12, 2023',
        description: 'A supply chain attack has compromised software updates from a major provider. Ensure you verify the source of all updates.',
        position: [50.8503, 4.3517], // Brussels
    },
    {
        id: 17,
        title: 'Critical Infrastructure Targeted',
        date: 'May 28, 2023',
        description: 'Critical infrastructure has been targeted in a cyberattack. Increased vigilance and protective measures are recommended.',
        position: [35.6762, 139.6503], // Tokyo
    },
    {
        id: 18,
        title: 'AI-Powered Phishing Attacks',
        date: 'April 22, 2023',
        description: 'AI-powered phishing attacks are becoming more sophisticated. Be cautious of unexpected or unusual emails.',
        position: [31.2304, 121.4737], // Shanghai
    },
    {
        id: 19,
        title: 'Cyber Espionage Campaign Detected',
        date: 'March 15, 2023',
        description: 'A cyber espionage campaign targeting government agencies has been detected. Enhanced security measures are advised.',
        position: [28.6139, 77.2090], // New Delhi
    },
    {
        id: 20,
        title: 'Telecom Sector Under Attack',
        date: 'February 9, 2023',
        description: 'The telecom sector has been under a series of DDoS attacks. Service disruptions may occur in affected regions.',
        position: [37.5665, 126.9780], // Seoul
    },
    // Expanded alerts
    {
        id: 21,
        title: 'Insider Threat Detected',
        date: 'August 10, 2024',
        description: 'An insider threat has been detected within a major organization. Increased monitoring and access controls are advised.',
        position: [40.7306, -73.9352], // New York
    },
    {
        id: 22,
        title: 'Voice Phishing (Vishing) Scam',
        date: 'July 22, 2024',
        description: 'Scammers are conducting voice phishing attacks to trick victims into providing sensitive information over the phone.',
        position: [51.1657, 10.4515], // Germany
    },
    {
        id: 23,
        title: 'Supply Chain Ransomware Attack',
        date: 'June 5, 2024',
        description: 'A ransomware attack has targeted the supply chain of several major companies. Verify all supply chain partners and their security measures.',
        position: [52.2297, 21.0122], // Warsaw
    },
    {
        id: 24,
        title: 'New DDoS Botnet Detected',
        date: 'May 15, 2024',
        description: 'A new botnet is being used to launch distributed denial-of-service (DDoS) attacks. Ensure your defenses are up to date.',
        position: [39.9042, 32.8597], // Ankara
    },
    {
        id: 25,
        title: 'Critical Vulnerability in Remote Desktop Protocol',
        date: 'April 30, 2024',
        description: 'A critical vulnerability has been discovered in Remote Desktop Protocol (RDP). Apply security patches and review remote access policies.',
        position: [34.0522, -118.2437], // Los Angeles
    },
    {
        id: 26,
        title: 'New Phishing Techniques Emerging',
        date: 'March 28, 2024',
        description: 'New phishing techniques are being employed by cybercriminals. Be cautious of unsolicited communications and verify sources.',
        position: [37.7749, -122.4194], // San Francisco
    },
    {
        id: 27,
        title: 'Data Exposure from Cloud Services',
        date: 'February 18, 2024',
        description: 'Sensitive data has been exposed due to misconfigured cloud services. Review and update your cloud security settings.',
        position: [40.7306, -73.9352], // New York
    },
    {
        id: 28,
        title: 'Breach of Government Agency',
        date: 'January 12, 2024',
        description: 'A breach has affected a government agency, compromising sensitive data. Increased security measures are recommended.',
        position: [37.5665, 126.9780], // Seoul
    },
    {
        id: 29,
        title: 'Malware in Software Updates',
        date: 'December 20, 2023',
        description: 'Malware has been detected in legitimate software updates. Verify the source of all updates and ensure integrity before installation.',
        position: [55.6761, 12.5683], // Copenhagen
    },
    {
        id: 30,
        title: 'Exploits Targeting IoT Devices',
        date: 'November 5, 2023',
        description: 'New exploits targeting IoT devices have been discovered. Update your firmware and review device security settings.',
        position: [48.2082, 16.3738], // Vienna
    },
];


const useAlertData = () => {
    const [alerts, setAlerts] = useState(initialAlerts);

    // Simulating an API call to fetch alerts data
    useEffect(() => {
        // Replace this with an actual API call
        const fetchAlerts = async () => {
            // Simulate network request
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setAlerts(initialAlerts); // Replace with fetched data
        };

        fetchAlerts();
    }, []);

    return alerts;
};

export default useAlertData;
